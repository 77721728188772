function Join() {
  return (
    <div>
    <h1 className="text-xl text-center mx-auto my-64 font-medium leading-tight text-center slide-in-bottom-h1 md:text-2xl lg:text-4xl xl:text-5xl lg:w-10/12 md:text-left">Currently in closed development. Come back in a couple months!</h1>
    </div>
  );
}


 
export default Join;